import { css, type Theme, useTheme } from '@emotion/react';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { trim } from 'lodash';
import { memo, useCallback, useMemo } from 'react';

import { TokenType, UNKNOWN_TOKEN_TYPE } from '@amalia/amalia-lang/tokens/types';
import { useBoolState } from '@amalia/ext/react/hooks';

import { type FormulaEditorToken, type FormulaEditorUnknownToken } from '../../../types/formulaEditorToken';

import { useFormulaEditorContext } from './FormulaEditor.context';
import { FunctionTokenPopover } from './function-token-popover/FunctionTokenPopover';
import { TokenNodePopover } from './token-node-popover/TokenNodePopover';

/**
 * The exact color of tokens is specific to the context. In token icons, we use the 100 strength.
 */
const useTokenBackgroundColor = (
  tokenType: FormulaEditorToken['type'] | FormulaEditorUnknownToken['type'],
): string | undefined => {
  const theme = useTheme();

  return {
    [TokenType.VARIABLE]: theme.ds.hues.cyan[50],
    [TokenType.FILTER]: theme.ds.hues.green[50],
    [TokenType.LINK]: theme.ds.hues.red[50],
    [TokenType.QUOTA]: theme.ds.hues.purple[50],
    [TokenType.FUNCTION]: undefined,
    [TokenType.KEYWORD]: theme.ds.hues.magenta[50],
    [TokenType.FIELD]: theme.ds.hues.orange[50],
    [TokenType.PROPERTY]: theme.ds.hues.orange[50],
    [TokenType.VIRTUAL_PROPERTY]: theme.ds.hues.orange[50],
    [TokenType.OBJECT]: theme.ds.hues.orange[50],
    [TokenType.RULE]: undefined,
    [TokenType.PLAN]: undefined,
    // Could happen when a formula is copy pasted from another plan and thus the token is not found in the current plan.
    [UNKNOWN_TOKEN_TYPE]: theme.ds.colors.danger[300],
  }[tokenType];
};

const tokenContainerCss = (theme: Theme) => css`
  ${theme.ds.typographies.bodyBaseMedium};
  display: inline-flex;
  color: ${theme.ds.colors.gray[900]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  padding: 0 6px;
`;

interface TokenNodeViewProps {
  readonly token: FormulaEditorToken | FormulaEditorUnknownToken;
  readonly position: number;
}

export const FormulaTokenNodeView = memo(function FormulaTokenNodeView({ token, position }: TokenNodeViewProps) {
  const backgroundColor = useTokenBackgroundColor(token.type);

  const { isHovered, setHoveredTrue, setHoveredFalse } = useBoolState(false, 'hovered');
  const { activeNode, editor, openTokenInTabHandler } = useFormulaEditorContext();

  /**
   * Give us a hint if the user is highlighting content (for copy/paster for example).
   * Then we disable popover which could be annoying.
   */
  const isHighlightingContent = useMemo(() => {
    const { from, to } = editor.view.state.selection;
    const selectedText = editor.state.doc.textBetween(from, to, '');

    return !!selectedText.length;
  }, [editor.state.doc, editor.view.state.selection]);

  const onClickHandler = useCallback(() => {
    setHoveredFalse();
    const tokenEndPosition = position + 1;
    editor.commands.setTextSelection(tokenEndPosition);
  }, [position, editor.commands, setHoveredFalse]);

  const handleClickOpenFunctionDocumentation = useCallback(() => {
    if (token.type === TokenType.FUNCTION) {
      openTokenInTabHandler(token);
    }
  }, [openTokenInTabHandler, token]);

  const isFunctionTokenPopoverOpened = useMemo(
    () => editor.isFocused && activeNode?.node.attrs['formula'] === token.formula && activeNode.position === position,
    [position, activeNode, editor, token],
  );

  return (
    <NodeViewWrapper
      as="span"
      data-text-content={token.formula}
    >
      <NodeViewContent
        as="span"
        css={[
          tokenContainerCss,
          token.type === TokenType.FUNCTION &&
            css`
              padding-left: 0;
              padding-right: 3px;
            `,
          backgroundColor &&
            css`
              background-color: ${backgroundColor};
            `,
        ]}
        onClick={'id' in token && token.id ? onClickHandler : undefined}
        onMouseEnter={setHoveredTrue}
        onMouseLeave={setHoveredFalse}
      >
        {token.type !== TokenType.FUNCTION ? (
          <TokenNodePopover
            isOpen={!!isHovered && !isHighlightingContent}
            token={token}
          >
            <div>{trim(token.name)}</div>
          </TokenNodePopover>
        ) : (
          <FunctionTokenPopover
            content={token.tooltipContent}
            isOpen={!!isFunctionTokenPopoverOpened && !isHighlightingContent}
            onClick={handleClickOpenFunctionDocumentation}
          >
            <div>{trim(token.name)}</div>
          </FunctionTokenPopover>
        )}
      </NodeViewContent>
    </NodeViewWrapper>
  );
});
