import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { formatTotal } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { assert } from '@amalia/ext/typescript';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { type ComputedVariable, type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';
import { type StatementThread } from '@amalia/payout-collaboration/comments/types';

import { KpiCardItem } from '../kpi-card-item/KpiCardItem';
import { KpiItem } from '../kpi-item/KpiItem';

interface StatementKpiProps {
  readonly isReadOnly: boolean;
  readonly canComment: boolean;
  readonly computedVariable: ComputedVariable;
  readonly variableDefinition: VariableDefinition;
  readonly statementThread?: StatementThread | null;
  readonly openStatementThread: (statementThreadId: string | null, variable: ComputedVariable) => void;
  readonly handleOpenModal?: () => void;
  readonly isKpiForecasted?: boolean;
  readonly useLegacyKpiCardView?: boolean;
}

export const StatementKpi = memo(function StatementKpi({
  isReadOnly,
  canComment,
  computedVariable,
  variableDefinition,
  statementThread,
  openStatementThread,
  handleOpenModal,
  isKpiForecasted,
  useLegacyKpiCardView,
}: StatementKpiProps) {
  const { statement, isForecastedView } = useStatementDetailContext();

  const { formatMessage } = useIntl();

  assert(statement, 'Statement is required');

  const kpiCurrency = computedVariable.currency || statement.currency;

  const openKpiComments = useCallback(
    () => openStatementThread(statementThread?.id || null, computedVariable),
    [statementThread, openStatementThread, computedVariable],
  );

  const { formattedValue, rawValue } = useMemo(() => {
    const valueResult = computedVariable.value;
    const isNumber =
      [FormatsEnum.number, FormatsEnum.percent].includes(variableDefinition.format) && typeof valueResult === 'number';

    // Only compute the truncated value if it's a small number
    const computeTruncated = isNumber && !!valueResult && valueResult <= 0.01;

    return {
      formattedValue: formatTotal(
        valueResult as ComputeEnginePrimitiveTypes,
        variableDefinition.format,
        kpiCurrency,
        statement.rate,
      ),
      rawValue: computeTruncated
        ? formatTotal(valueResult, variableDefinition.format, kpiCurrency, statement.rate)
        : '',
    };
  }, [computedVariable, variableDefinition, statement, kpiCurrency]);

  const editKpiBtnTitle = isForecastedView
    ? formatMessage(
        { defaultMessage: 'Make a simulation on {variable_name}' },
        { variable_name: variableDefinition.name.trim() },
      )
    : formatMessage(
        { defaultMessage: 'Overwrite on {variable_name}' },
        { variable_name: variableDefinition.name.trim() },
      );

  const props = {
    canComment,
    canOverwrite: !isReadOnly,
    isThreadOpen: !!statementThread,
    isThreadReviewed: !!statementThread && statementThread.thread.isReviewed,
    isForecasted: isKpiForecasted,
    isOverwritten: !!computedVariable.overwrite,
    name: variableDefinition.name.trim(),
    overwriteTooltipText: editKpiBtnTitle,
    rawValue,
    value: formattedValue,
    commentTooltipText: formatMessage(
      { defaultMessage: 'Comment on {variable_name}' },
      { variable_name: variableDefinition.name.trim() },
    ),
    onClickComment: openKpiComments,
    onClickOverwrite: handleOpenModal,
  };

  return useLegacyKpiCardView ? <KpiCardItem {...props} /> : <KpiItem {...props} />;
});
