import { IconPlus } from '@tabler/icons-react';
import { noop } from 'lodash';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  addOperand,
  createLogicalOperatorBlock,
  type FormulaBuilderForm,
  removeOperand,
  setFormulaBuilderRootBlock,
  setLogicalOperator,
  setOperand,
} from '@amalia/amalia-lang/formula/form/types';
import { type FormulaBuilderLogicalOperatorType } from '@amalia/amalia-lang/formula/types';
import {
  Button,
  ButtonVariant,
  type RadioButtonGroupProps,
  RadioButtonSingle,
  Tooltip,
} from '@amalia/design-system/components';
import { useBoolState, useHover } from '@amalia/ext/react/hooks';

import { useAndOrOptions } from '../../hooks/use-and-or-options/useAndOrOptions';
import { CreateConditionForm, type CreateConditionFormProps } from '../create-condition-form/CreateConditionForm';
import {
  FormulaConditionGroup,
  type FormulaConditionGroupProps,
} from '../formula-condition-group/FormulaConditionGroup';

import { useFormulaBuilderContext } from './FormulaBuilder.context';
import * as styles from './FormulaBuilder.styles';

export type FormulaBuilderProps = {
  readonly formula: FormulaBuilderForm;
  readonly onChange?: (updatedFormula: FormulaBuilderForm) => void;
};

export const FormulaBuilder = memo(function FormulaBuilder({ formula, onChange = noop }: FormulaBuilderProps) {
  const { setActiveConditionId, customObjectDefinition } = useFormulaBuilderContext();
  const [isAndOrOptionsGroupActive, andOrOptionsGroupActiveHoverProps] = useHover();
  const andOrOptions = useAndOrOptions();

  const { isOperandPopoverOpen, setOperandPopoverOpenFalse, setOperandPopoverOpen } = useBoolState(
    false,
    'operandPopoverOpen',
  );

  const handleChangeRootBlock: (rootBlock: FormulaBuilderForm['root']) => void = useCallback(
    (rootBlock) => onChange(setFormulaBuilderRootBlock(formula, rootBlock)),
    [onChange, formula],
  );

  const handleCreateCondition: CreateConditionFormProps['onCreateCondition'] = useCallback(
    (condition) => {
      const logicalOperatorBlock = createLogicalOperatorBlock(condition);
      handleChangeRootBlock(addOperand(formula.root, logicalOperatorBlock));
      setActiveConditionId(condition.id);
      setOperandPopoverOpenFalse();
    },
    [handleChangeRootBlock, formula, setActiveConditionId, setOperandPopoverOpenFalse],
  );

  const handleChangeRootLogicalOperator: Required<
    RadioButtonGroupProps<FormulaBuilderLogicalOperatorType>
  >['onChange'] = useCallback(
    (operator) => handleChangeRootBlock(setLogicalOperator(formula.root, operator)),
    [handleChangeRootBlock, formula],
  );

  const handleRemoveRootOperand: Required<FormulaConditionGroupProps>['onDelete'] = useCallback(
    (operandId) => handleChangeRootBlock(removeOperand(formula.root, operandId)),
    [handleChangeRootBlock, formula],
  );

  const handleChangeRootOperand: Required<FormulaConditionGroupProps>['onChange'] = useCallback(
    (operand) => handleChangeRootBlock(setOperand(formula.root, operand)),
    [handleChangeRootBlock, formula],
  );

  const hasAtLeastOneCondition = !!formula.root.operands.length;

  return (
    <div css={styles.formulaBuilder}>
      {!!hasAtLeastOneCondition && (
        <div css={styles.conditionsGroups}>
          {formula.root.operands.map((operand, index) => (
            <Fragment key={operand.id}>
              {index > 0 && (
                <div {...andOrOptionsGroupActiveHoverProps}>
                  <RadioButtonSingle
                    isActive={isAndOrOptionsGroupActive}
                    name="operandSwitcherGroup"
                    options={andOrOptions}
                    value={formula.root.logicalOperator}
                    onChange={handleChangeRootLogicalOperator}
                  />
                </div>
              )}
              {index === 0 && formula.root.operands.length > 1 && <div />}

              <FormulaConditionGroup
                condition={operand}
                path={`root.operands[${index}]`}
                onChange={handleChangeRootOperand}
                onDelete={handleRemoveRootOperand}
              />
            </Fragment>
          ))}
        </div>
      )}

      <CreateConditionForm
        isOpen={isOperandPopoverOpen}
        onChangeIsOpen={setOperandPopoverOpen}
        onCreateCondition={handleCreateCondition}
      >
        {/* Cannot click on the button if the first child is a tooltip */}
        {!customObjectDefinition ? (
          <Tooltip
            content={
              <FormattedMessage defaultMessage="To add conditions, start by selecting a data object in the settings on the right." />
            }
          >
            <Button
              disabled
              icon={<IconPlus />}
              size={Button.Size.SMALL}
              variant={Button.Variant.PRIMARY_LIGHT}
            >
              <FormattedMessage defaultMessage="Add a group of conditions" />
            </Button>
          </Tooltip>
        ) : (
          <Button
            icon={<IconPlus />}
            size={Button.Size.SMALL}
            variant={hasAtLeastOneCondition ? Button.Variant.PRIMARY_LIGHT : ButtonVariant.PRIMARY}
          >
            {hasAtLeastOneCondition ? (
              <FormattedMessage defaultMessage="Add a group of conditions" />
            ) : (
              <FormattedMessage defaultMessage="Add a condition" />
            )}
          </Button>
        )}
      </CreateConditionForm>
    </div>
  );
});
