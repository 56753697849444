import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, Typography } from '@amalia/design-system/components';
import { useRemoveDashboardAsHome } from '@amalia/reporting/dashboards-v2/state';

interface DashboardUnsetHomeModalProps {
  readonly isOpen: boolean;
  readonly dashboardName: string;
  readonly onClose: () => void;
}

export const DashboardUnsetHomeModal = memo(function DashboardUnsetHomeModal({
  dashboardName,
  onClose,
  isOpen,
}: DashboardUnsetHomeModalProps) {
  const { mutate: removeDashboardAsHome } = useRemoveDashboardAsHome();

  const handleDeleteConfirm = useCallback(() => {
    removeDashboardAsHome();
    onClose();
  }, [onClose, removeDashboardAsHome]);

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.MEDIUM}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Remove dashboard from home" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            <FormattedMessage
              defaultMessage="Are you sure you want to remove <bold>“{dashboardName}”</bold> from your homepage?"
              values={{
                bold: (chunk) => <Typography variant={Typography.Variant.BODY_BASE_BOLD}>{chunk}</Typography>,
                dashboardName,
              }}
            />
          </Typography>
          <br />
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            <FormattedMessage
              defaultMessage="Doing so sets your homepage to the default dashboard <bold>“Payout Overview.”</bold>"
              values={{ bold: (chunk) => <Typography variant={Typography.Variant.BODY_BASE_BOLD}>{chunk}</Typography> }}
            />
          </Typography>
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={handleDeleteConfirm}>
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
