import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';
import { assert } from '@amalia/ext/typescript';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import { PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import {
  type CustomReportConfigurationField,
  makeUniqueCustomReportFieldIdentifier,
} from '@amalia/reporting/custom-reports/shared';
import { type DashboardChartResult } from '@amalia/reporting/dashboards-v2/types';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

import { useFormatChartValue } from '../../../common/hooks/useFormatChartValue';
import { type ChartColumnMetadata } from '../../../common/types/charts.types';

export const useChartColumn = ({
  chartData,
  columnIdentifier,
}: {
  chartData?: DashboardChartResult;
  columnIdentifier: Pick<CustomReportConfigurationField, 'identifier' | 'joins'>;
}): ChartColumnMetadata | null => {
  const column = (chartData?.columns || []).find(
    (c) => c.identifier === makeUniqueCustomReportFieldIdentifier(chartData!.source, columnIdentifier),
  );
  const { data: company } = useCurrentCompany();

  const isQuarterPeriodColumn = useMemo(() => {
    if (
      company.statementFrequency !== PeriodFrequencyEnum.quarter ||
      !chartData ||
      column?.format !== FormatsEnum.date
    ) {
      return false;
    }
    return chartData.items
      .map((data) => data[column.identifier] as string)
      .every((dateString) => dayjs.utc(dateString).startOf('quarter').unix() === dayjs.utc(dateString).unix());
  }, [chartData, column, company.statementFrequency]);

  const formatChartValue = useFormatChartValue();
  const currency =
    (column ? (chartData?.items[0]?.[column.identifier] as CurrencyValue | undefined)?.symbol : undefined) ||
    company.currency;

  const formatTick: ChartColumnMetadata['formatTick'] = useCallback(
    (value) => {
      assert(column, 'Column cannot be undefined when formatting ticks');
      return formatChartValue({
        value:
          column.format === FormatsEnum.currency && !isNil(value)
            ? { value: value as number, symbol: currency }
            : value,
        format: column.format,
        compact: true,
        isQuarterPeriod: isQuarterPeriodColumn,
      });
    },
    [column, formatChartValue, currency, isQuarterPeriodColumn],
  );

  const formatValue: ChartColumnMetadata['formatValue'] = useCallback(
    (value) => {
      assert(column, 'Column cannot be undefined when formatting values');
      return formatChartValue({ value, format: column.format, compact: true });
    },
    [column, formatChartValue],
  );

  const formatTooltipValue: ChartColumnMetadata['formatTooltipValue'] = useCallback(
    (value) => {
      assert(column, 'Column cannot be undefined when formatting values');
      return formatChartValue({ value, format: column.format, compact: false });
    },
    [column, formatChartValue],
  );

  return useMemo(
    () =>
      column
        ? {
            column,
            dataKey: column.format === FormatsEnum.currency ? `${column.identifier}.value` : column.identifier,
            formatTick,
            formatValue,
            formatTooltipValue,
          }
        : null,
    [column, formatTick, formatValue, formatTooltipValue],
  );
};
