import { css } from '@emotion/react';
import { forwardRef, memo, type ComponentPropsWithoutRef, type ForwardedRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatUserFullName } from '@amalia/core/types';
import { Avatar, ButtonLink, Group, Tooltip, UnstyledButton } from '@amalia/design-system/components';
import { isLinkElement } from '@amalia/ext/react-router-dom';
import { type MergeAll } from '@amalia/ext/typescript';

import { UsersAvatarStackDropdown } from './dropdown/UsersAvatarStackDropdown';
import { AVATAR_OVERLAP, UsersAvatarStackSizeToAvatarSize } from './UsersAvatarStack.constants';
import * as styles from './UsersAvatarStack.styles';
import { UsersAvatarStackSize, type UsersAvatarStackUser } from './UsersAvatarStack.types';

export type UsersAvatarStackProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'div'>, 'children'>,
    {
      /** List of users to show. */
      readonly users?: UsersAvatarStackUser[];
      /** Maximum number of avatars to show. The avatar with the remaining users is included in that number. */
      readonly maxUsers?: number;
      /** Size of avatars. */
      readonly size?: UsersAvatarStackSize;
      /** Whether to disable the dropdown. */
      readonly dropdownDisabled?: boolean;
    },
  ]
>;

const DEFAULT_USERS: Required<UsersAvatarStackProps>['users'] = [];

const UsersAvatarStackForwardRef = forwardRef(function UsersAvatarStack(
  {
    users = DEFAULT_USERS,
    maxUsers = 3,
    size = UsersAvatarStackSize.MEDIUM,
    dropdownDisabled = false,
    ...props
  }: UsersAvatarStackProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const shouldShowExtraAvatar = users.length > maxUsers;
  const usersToShow = shouldShowExtraAvatar ? users.slice(0, maxUsers - 1) : users;
  const avatarsCount = Math.min(users.length, maxUsers);

  return (
    <Group
      {...props}
      ref={ref}
      align="center"
      css={css`
        width: ${avatarsCount * UsersAvatarStackSizeToAvatarSize[size] - (avatarsCount - 1) * AVATAR_OVERLAP}px;

        &:empty {
          display: none;
        }

        > * {
          flex: none;
        }
      `}
    >
      {usersToShow.map(({ user, to }, index) => (
        <Tooltip
          key={user.id}
          content={
            <Group
              align="center"
              gap={8}
            >
              <span>{formatUserFullName(user)}</span>

              {!!to && (
                <ButtonLink
                  size={ButtonLink.Size.SMALL}
                  to={to}
                  variant={ButtonLink.Variant.PRIMARY_LIGHT}
                >
                  {(isLinkElement(to) ? to.props.children : undefined) || <FormattedMessage defaultMessage="View" />}
                </ButtonLink>
              )}
            </Group>
          }
        >
          <Avatar
            size={UsersAvatarStackSizeToAvatarSize[size]}
            user={user}
            css={(theme) => css`
              border: 1px solid ${theme.ds.colors.gray[0]};
              box-sizing: border-box;
              position: relative;
              left: ${index * AVATAR_OVERLAP * -1}px;
            `}
          />
        </Tooltip>
      ))}

      {!!shouldShowExtraAvatar &&
        (dropdownDisabled ? (
          <UnstyledButton css={styles.moreUsersButton(maxUsers, size)}>
            <FormattedMessage
              defaultMessage="+{count, number}"
              values={{ count: users.length - maxUsers + 1 }}
            />
          </UnstyledButton>
        ) : (
          <UsersAvatarStackDropdown users={users}>
            <UnstyledButton css={styles.moreUsersButton(maxUsers, size)}>
              <FormattedMessage
                defaultMessage="+{count, number}"
                values={{ count: users.length - maxUsers + 1 }}
              />
            </UnstyledButton>
          </UsersAvatarStackDropdown>
        ))}
    </Group>
  );
});

export const UsersAvatarStack = Object.assign(memo(UsersAvatarStackForwardRef), {
  Size: UsersAvatarStackSize,
});
