import { css } from '@emotion/react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getPeriodStringFromUserDate, getRelativePeriodString, getUserDateFromPeriodDate } from '@amalia/core/types';
import { DatePickerBase, Group, IconButton } from '@amalia/design-system/components';
import { PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

import { DatePickerInput } from './date-picker-input/DatePickerInput';

type PeriodSelectorProps = {
  readonly frequency: PeriodFrequencyEnum;
  readonly timestamp: number;
  readonly onPeriodChange: (dateString: string) => void;
};

export const PeriodSelector = memo(function PeriodSelectorBase({
  frequency,
  timestamp,
  onPeriodChange,
}: PeriodSelectorProps) {
  const { formatMessage } = useIntl();

  const datePickerOptions = useMemo(
    () => ({
      dateFormat: frequency === PeriodFrequencyEnum.month ? 'MMMM yyyy' : 'QQQ yyyy',
      showQuarterYearPicker: frequency !== PeriodFrequencyEnum.month,
      showMonthYearPicker: frequency === PeriodFrequencyEnum.month,
    }),
    [frequency],
  );

  /**
   * Init date picker date with timezone offset
   * (date picker only works with user date, and here we have a utc period date, so we apply user timezone offset to it).
   */
  const datePickerSelectedDate = useMemo(() => getUserDateFromPeriodDate(timestamp), [timestamp]);

  /**
   * We receive a user date from datepicker. We need to unapply timezone offset from it to have a utc period compatible date.
   */
  const onDatePickerChange = useCallback(
    (selectedDate: Date | null) => {
      if (selectedDate) {
        // Date picker output is not utc. So we need to apply offset to it to match period utc date.
        const formattedDate = getPeriodStringFromUserDate(selectedDate);
        onPeriodChange(formattedDate);
      }
    },
    [onPeriodChange],
  );

  /**
   * Callback when user click on "previous" / "next" period.
   */
  const onClickRelativePeriod = useCallback(
    (offset: number) => {
      const formattedDate = getRelativePeriodString(timestamp, offset, frequency);

      onPeriodChange(formattedDate);
    },
    [frequency, timestamp, onPeriodChange],
  );

  const onClickPrevious = useCallback(() => onClickRelativePeriod(-1), [onClickRelativePeriod]);
  const onClickNext = useCallback(() => onClickRelativePeriod(1), [onClickRelativePeriod]);

  return (
    <Group
      inline
      align="center"
      css={css`
        height: 36px;
      `}
    >
      <IconButton
        icon={<IconChevronLeft />}
        label={formatMessage({ defaultMessage: 'Previous period' })}
        onClick={onClickPrevious}
      />
      {/* Wrap inside a div, otherwise the modal messes with the flex container gap. */}
      <div>
        <DatePickerBase
          {...datePickerOptions}
          popperPlacement="bottom"
          value={datePickerSelectedDate}
          onChange={onDatePickerChange}
        >
          {({ isOpen: isCalendarOpen }) => <DatePickerInput isOpen={isCalendarOpen} />}
        </DatePickerBase>
      </div>
      <IconButton
        icon={<IconChevronRight />}
        label={formatMessage({ defaultMessage: 'Next period' })}
        onClick={onClickNext}
      />
    </Group>
  );
});
