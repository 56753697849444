import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { ConfigurationError } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineDayjsInput } from '../dates.types';

export default new AmaliaFunctionDefault<[ComputeEngineDayjsInput, number | string, string], number>({
  name: AmaliaFunctionKeys.dateOffset,
  category: AmaliaFunctionCategory.DATES,
  nbParamsRequired: 3,
  description: 'Offsets a date.',

  exec: (date, offsetValue, interval) => {
    if (!['day', 'month', 'year'].includes(interval)) {
      throw new ConfigurationError(`Offset value "${interval}" should be one of the following: day, month, year`);
    }
    return dayjs(date, 'X')
      .add(+offsetValue, interval as 'day' | 'month' | 'year')
      .unix();
  },

  params: [
    {
      name: 'date',
      description:
        'Date to use as reference: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
    { name: 'offsetValue', description: 'Number of days, months, years, to offset (can be negative)' },
    { name: 'interval', description: 'Interval of the offset (can be `day` or `month` or `year`)' },
  ],

  examples: [
    {
      desc: 'Returns "2023-03-19"',
      formula: 'dateOffset(toDate("2023-05-19"), -2, "month")' as AmaliaFormula,
      result: 1_679_184_000,
    },
    {
      desc: 'Returns "2023-05-19"',
      formula: 'dateOffset(toDate("2023-05-17"), 2, "day")' as AmaliaFormula,
      result: 1_684_454_400,
    },
    {
      desc: 'Returns a date 2 months before the Close Date.',
      formula: 'dateOffset(opportunity.closeDate, -2, "month")' as AmaliaFormula,
    },
    {
      desc: 'Returns a date 1 year after the statement start date.',
      formula: 'dateOffset(statementPeriod.startDate, 1, "year")' as AmaliaFormula,
    },
  ],
});
