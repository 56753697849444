import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const DataConnectorPage = lazy(async () => ({
  default: (await import('./connectors/connector/DataConnectorPage')).DataConnectorPage,
}));

export const CustomObjectRoutes = memo(function CustomObjectRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="data">
      <RootRoutes>
        <Route
          path={routes.DATA_CONNECTOR}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connector' })} />
              <DataConnectorPage />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
