import { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Input, Modal } from '@amalia/design-system/components';
import { useDuplicateDashboard } from '@amalia/reporting/dashboards-v2/state';
import { type Dashboard } from '@amalia/reporting/dashboards-v2/types';

const formatDashboardName = (dashboardName: string) => `${dashboardName} (copy)`;

interface DashboardV2DuplicateModalProps {
  readonly dashboard: Dashboard;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly shouldRedirectToDashboardAfterDuplicate?: boolean;
}

export const DashboardV2DuplicateModal = memo(function DashboardV2DuplicateModal({
  dashboard,
  isOpen,
  shouldRedirectToDashboardAfterDuplicate = false,
  onClose,
}: DashboardV2DuplicateModalProps) {
  const navigate = useNavigate();

  const [dashboardName, setDashboardName] = useState(formatDashboardName(dashboard.name));

  useEffect(() => {
    setDashboardName(formatDashboardName(dashboard.name));
  }, [dashboard.name]);

  const { mutate: duplicateDashboard, isPending: isDuplicateDashboardPending } = useDuplicateDashboard(dashboard.id);

  const handleChangeDashboardName = useCallback((newDashboardName: string) => {
    setDashboardName(newDashboardName);
  }, []);

  const handleClickSubmit = useCallback(() => {
    duplicateDashboard(
      { name: dashboardName.trim() },
      {
        onSuccess: (duplicatedDashboard: Dashboard) => {
          onClose();

          if (shouldRedirectToDashboardAfterDuplicate) {
            navigate(generatePath(routes.DASHBOARD_V2, { dashboardId: duplicatedDashboard.id }));
          }
        },
      },
    );
  }, [dashboardName, duplicateDashboard, navigate, onClose, shouldRedirectToDashboardAfterDuplicate]);

  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      variant={Modal.Variant.DEFAULT}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              defaultMessage='Duplicate dashboard "{dashboardName}"'
              values={{ dashboardName: dashboard.name }}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Input
            required
            label={<FormattedMessage defaultMessage="New dashboard name" />}
            value={dashboardName}
            onChange={handleChangeDashboardName}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          disabled={!dashboardName.trim() || isDuplicateDashboardPending}
          isLoading={isDuplicateDashboardPending}
          onClick={handleClickSubmit}
        >
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
