import { useQuery } from '@tanstack/react-query';

import { assert } from '@amalia/ext/typescript';
import { type RelativePeriodKeyword } from '@amalia/payout-definition/periods/types';
import { type PlanIsHiddenQueryChoices } from '@amalia/payout-definition/plans/types';

import { getOvertimeWidget } from '../services/dashboards/dashboards.repository';

enum OVER_TIME_QUERY_KEYS {
  OVERTIME_DATA = 'OVERTIME_DATA',
}

export const useUserOvertimeData = ({
  periodIds,
  referenceDate,
  userId,
  planId,
}: {
  periodIds: RelativePeriodKeyword[];
  referenceDate?: string;
  userId?: string;
  planId?: string;
}) =>
  useQuery({
    enabled: !!userId && !!planId && !!referenceDate,
    queryKey: [OVER_TIME_QUERY_KEYS.OVERTIME_DATA, periodIds, referenceDate, userId, planId],
    queryFn: async () => {
      assert(userId, 'userId is required');
      assert(planId, 'planId is required');
      return getOvertimeWidget({
        periodIds,
        referenceDate,
        userIds: [userId],
        planIds: [planId],
      });
    },
  });

export const useOverTimeData = ({
  periodIds,
  referenceDate,
  userIds,
  planIds,
  teamIds,
  planHiddenStatus,
}: {
  periodIds: RelativePeriodKeyword[];
  referenceDate?: string;
  userIds?: string[];
  planIds?: string[];
  teamIds?: string[];
  planHiddenStatus?: PlanIsHiddenQueryChoices;
}) =>
  useQuery({
    queryKey: [
      OVER_TIME_QUERY_KEYS.OVERTIME_DATA,
      periodIds,
      referenceDate,
      userIds,
      planIds,
      teamIds,
      planHiddenStatus,
    ],
    queryFn: async () =>
      getOvertimeWidget({
        periodIds,
        referenceDate,
        userIds,
        planIds,
        teamIds,
        planHiddenStatus,
      }),
  });
