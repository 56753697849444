import { css, useTheme } from '@emotion/react';
import { ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconDots, IconEye, IconPencil } from '@tabler/icons-react';
import { type MouseEventHandler, memo } from 'react';
import { useIntl } from 'react-intl';

import { getTokenColor } from '@amalia/amalia-lang/tokens/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { IconAction, MenuDropdown, type TablerIconElement, Typography } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';
import { Tooltip } from '@amalia/lib-ui';

export interface ClickableOption {
  value: any;
  label: string;
  format: {
    symbol: string;
    label: string;
  };
  tooltipTitle: string;
  fullMachineName: string;
  formulaToPrint: string;
  description: string;
  planName: string;
  planId: string;
  id: string;
  definitionMachinename?: string | null;
}

interface FormulaEditorOptionProps {
  readonly option: ClickableOption;
  readonly handleListItemClick: MouseEventHandler<HTMLLIElement>;
  readonly formatSymbolClass: string;
  readonly tokenType?: TokenType;
  readonly displayContext?: boolean;
  readonly compact?: boolean;
  readonly openInNewTab?: MouseEventHandler<HTMLButtonElement>;
  readonly actionsToRender?: {
    icon: TablerIconElement;
    label: string;
    callback: (e: unknown, id: string) => void;
  }[];
  readonly clickedCategory?: TokenType;
}

const useStyles = makeStyles<AmaliaThemeType, { backgroundColor?: string }>((theme) => ({
  planName: {
    marginLeft: theme.spacing(1),
  },
  italic: {
    '&:hover': {
      fontStyle: 'italic',
      '& $itemAction': {
        opacity: 1,
      },
    },
  },
  itemAction: {
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'absolute',
    right: '0px',
    display: 'flex',
    opacity: 0,
    top: 0,
    bottom: 0,
  },
  itemsContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  formatTooltip: {
    color: theme.palette.tertiary.main,
  },
  itemLabel: {
    color: theme.palette.common.black,
    backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : ''),
    borderRadius: '4px',
    padding: '1px 2px',
  },
}));

const DESIGNER_OBJECTS_CANT_BE_EDITED = [
  TokenType.FUNCTION,
  TokenType.KEYWORD,
  TokenType.PROPERTY,
  TokenType.VIRTUAL_PROPERTY,
];

export const FormulaEditorOption = memo(function FormulaEditorOption({
  option,
  handleListItemClick,
  formatSymbolClass,
  tokenType,
  displayContext,
  compact,
  openInNewTab,
  actionsToRender,
  clickedCategory,
}: FormulaEditorOptionProps) {
  const theme = useTheme();
  const classes = useStyles({ backgroundColor: tokenType ? getTokenColor(tokenType, theme) : undefined });
  const { formatMessage } = useIntl();

  return (
    <ListItem
      key={option.value}
      className={compact ? classes['italic'] : null}
      data-testid={option.id}
      onClick={handleListItemClick}
    >
      <ListItemText
        primary={
          <Typography
            as="div"
            className={classes['itemsContainer']}
            variant={Typography.Variant.BODY_BASE_REGULAR}
          >
            <Tooltip
              placement="right"
              title={
                <div
                  css={css`
                    padding: 8px;
                    width: 400px;
                  `}
                >
                  <Typography
                    as="div"
                    variant={Typography.Variant.BODY_BASE_REGULAR}
                  >
                    {option.tooltipTitle}
                    {option.format ? (
                      <span
                        css={css`
                          color: ${theme.ds.colors.primary[400]};
                        `}
                      >
                        &nbsp;
                        {option.format.label}
                      </span>
                    ) : null}
                  </Typography>

                  {!!option.fullMachineName && (
                    <Typography
                      as="div"
                      variant={Typography.Variant.BODY_BASE_MEDIUM}
                    >
                      {option.fullMachineName}
                    </Typography>
                  )}

                  {!!option.formulaToPrint && (
                    <div>
                      <Typography
                        variant={Typography.Variant.MONOSPACE_BASE}
                        css={css`
                          margin-top: 16px;
                        `}
                      >
                        {option.formulaToPrint}
                      </Typography>
                    </div>
                  )}

                  {!!option.description && (
                    <div
                      css={css`
                        margin-top: 16px;
                      `}
                    >
                      {option.description}
                    </div>
                  )}
                </div>
              }
            >
              <span className={classes['itemLabel']}>{option.label}</span>
            </Tooltip>

            {!!option.format && (
              <Typography
                className={formatSymbolClass}
                variant={Typography.Variant.BODY_SMALL_BOLD}
              >
                {option.format.symbol}
              </Typography>
            )}

            {!!displayContext && (
              <Typography
                className={classes['planName']}
                variant={Typography.Variant.BODY_SMALL_REGULAR}
                css={css`
                  color: ${theme.ds.colors.gray[500]};
                `}
              >
                {option.planName}
              </Typography>
            )}

            {!!compact && !!openInNewTab && (
              <div className={classes['itemAction']}>
                <IconAction
                  icon={DESIGNER_OBJECTS_CANT_BE_EDITED.includes(clickedCategory) ? <IconEye /> : <IconPencil />}
                  label={formatMessage({ defaultMessage: 'Open in tab' })}
                  size={IconAction.Size.SMALL}
                  onClick={openInNewTab}
                />
                {!!actionsToRender && (
                  <MenuDropdown
                    content={
                      <div>
                        {actionsToRender.map(({ icon, label, callback }) => (
                          <MenuDropdown.Item
                            key={label}
                            icon={icon}
                            label={label}
                            onClick={(e) => {
                              callback(e, option.id);
                            }}
                          />
                        ))}
                      </div>
                    }
                  >
                    <IconAction
                      icon={<IconDots />}
                      label={formatMessage({ defaultMessage: 'More options' })}
                      size={IconAction.Size.SMALL}
                    />
                  </MenuDropdown>
                )}
              </div>
            )}
          </Typography>
        }
      />
    </ListItem>
  );
});
