import { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { ChangelogView } from '@amalia/amalia-meta/changelog/views/changelog';
import { QuotasListView } from '@amalia/assignments/views/quotas/list';
import { QuotaValuesView } from '@amalia/assignments/views/quotas/values';
import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { ConnectorsListView } from '@amalia/data-capture/connectors/views/list';
import { ConnectorsOAuthCallbackView } from '@amalia/data-capture/connectors/views/oauth-callback';
import { CapturedRecordModelDetailsView } from '@amalia/data-capture/record-models/views/details';
import { CustomObjectRoutes } from '@amalia/data-capture/records/views/group';
import { OverwritesView } from '@amalia/data-correction/overwrites/views/overwrites';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes, UseParams } from '@amalia/ext/react-router-dom';
import { checkLocalStorageOnLoad } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum, userProfileSubject, viewTeamSubject } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector, useCurrentUser } from '@amalia/kernel/auth/state';
import { CommissionReportView } from '@amalia/payout-calculation/commission-report/views/group';
import { StatementDetailsView } from '@amalia/payout-calculation/statements/views/details';
import { StatementsListView } from '@amalia/payout-calculation/statements/views/list';
import { StatementNotFoundView } from '@amalia/payout-calculation/statements/views/not-found';
import { ThreadsView } from '@amalia/payout-collaboration/comments/views/threads';
import { DesignerRoutes } from '@amalia/payout-definition/designer/views/group';
import { PlanHubRootView } from '@amalia/payout-definition/plans/views/hub/root';
import { PlanHubRuleDesignerView } from '@amalia/payout-definition/plans/views/hub/rule-designer';
import { PlansListView } from '@amalia/payout-definition/plans/views/list';
import { PlanAgreementDetailsRoutes } from '@amalia/plan-agreements/views/details';
import { PlanAgreementEditRoutes } from '@amalia/plan-agreements/views/edit';
import { PlanAgreementsListRoutes } from '@amalia/plan-agreements/views/list';
import { PlanAgreementPreviewRoutes } from '@amalia/plan-agreements/views/preview';
import { CustomReportRoutes } from '@amalia/reporting/custom-reports/views';
import { DashboardsV2DetailsRoutes } from '@amalia/reporting/dashboards-v2/views/details';
import { DashboardsV2ListRoutes } from '@amalia/reporting/dashboards-v2/views/list';
import { DataExportsDownloadView } from '@amalia/reporting/exports/views/download';
import { DashboardView, HomeRedirectView, HomeView } from '@amalia/reporting/homepage/views/group';
import { SuperAdminRoutes } from '@amalia/superadmin/views';
import { CompanySettings } from '@amalia/tenants/companies/settings/layout';
import { IntegrationOauthCallback } from '@amalia/tenants/companies/settings/views/group';
import { AuditListView } from '@amalia/tenants/monitoring/audit/views/list';
import { TeamDetailsView } from '@amalia/tenants/teams/views/details';
import { TeamsListView } from '@amalia/tenants/teams/views/list';
import { tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';
import { DirectoryView } from '@amalia/tenants/users/profile/views/directory';
import { MemberProfileView } from '@amalia/tenants/users/profile/views/member';
import { CurrentUserProfileView } from '@amalia/tenants/users/profile/views/profile';

const App = function App() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();

  useEffect(
    // Clear localstorage if company is different
    () => user && checkLocalStorageOnLoad(user),
    [user],
  );

  return (
    <RootRoutes>
      <Route
        path={routes.AUTH_CONNECTOR_CALLBACK}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
            <ConnectorsOAuthCallbackView />
          </Fragment>
        }
      />

      <Route
        path={routes.AUTH_INTEGRATION_CALLBACK}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
            <IntegrationOauthCallback />
          </Fragment>
        }
      />

      <Route
        path={routes.HOME}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.RepHome)}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Home' })} />
            <HomeView />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.DATA_EXPORT}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Data export' })} />
            <DataExportsDownloadView />
          </Fragment>
        }
      />

      <Route
        element={<DesignerRoutes />}
        path={`${routes.DESIGNER}/*`}
      />

      <Route
        path={routes.PLAN_HUB_RULE}
        element={
          <Layout hideNavigationMenu>
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan hub' })} />
              <PlanHubRuleDesignerView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={`${routes.PLAN_HUB}/*`}
        element={
          <Layout currentPage="plans">
            <PlanHubRootView />
          </Layout>
        }
      />

      <Route
        path={routes.PLANS}
        element={
          <Layout currentPage="plans">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plans' })} />
              <PlansListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.TEAMS}
        element={
          <Layout currentPage="teams">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Team)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Teams' })} />
              <TeamsListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.TEAM_DETAILS}
        element={
          <Layout currentPage="teams">
            <UseParams<{ teamId: string }>>
              {({ teamId }) => (
                <AbilityRouteProtector
                  can={(ability) => ability.can(ActionsEnum.view, viewTeamSubject({ team: { id: teamId } }))}
                >
                  <AmaliaHead title={formatMessage({ defaultMessage: 'Team details' })} />
                  <TeamDetailsView teamId={teamId} />
                </AbilityRouteProtector>
              )}
            </UseParams>
          </Layout>
        }
      />

      <Route
        path={routes.QUOTAS}
        element={
          <Layout currentPage="quotas">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Quota)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Quotas' })} />
              <QuotasListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.QUOTAS_VALUE}
        element={
          <Layout currentPage="quotas">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Quota)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Quota values' })} />
              <QuotaValuesView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.AUDIT}
        element={
          <Layout currentPage="audit">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Audit)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Audit' })} />
              <AuditListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        element={<PlanAgreementsListRoutes />}
        path={routes.PLAN_AGREEMENTS_LIST}
      />

      <Route
        element={<PlanAgreementEditRoutes />}
        path={routes.PLAN_AGREEMENT_EDIT}
      />

      <Route
        element={<PlanAgreementDetailsRoutes />}
        path={routes.PLAN_AGREEMENT_ASSIGNMENT}
      />

      {/* For backward compatibility with old URL */}
      <Route
        element={<PlanAgreementDetailsRoutes />}
        path={routes.PLAN_AGREEMENT_ASSIGNMENT_OLD}
      />

      <Route
        element={<PlanAgreementPreviewRoutes />}
        path={routes.PLAN_AGREEMENT_PREVIEW}
      />

      <Route
        path={routes.CHANGELOG}
        element={
          <Layout>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Changelog' })} />
            <ChangelogView />
          </Layout>
        }
      />

      <Route
        path={routes.OVERWRITES}
        element={
          <Layout currentPage="overwrites">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Overwrites' })} />
              <OverwritesView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.COMMISSION_REPORT}
        element={
          <Layout currentPage="reporting">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Payment)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Payments' })} />
              <CommissionReportView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={tenantUsersRoutes.PROFILE_PAGE}
        element={
          <Layout currentPage="directory">
            <CurrentUserProfileView />
          </Layout>
        }
      />

      <Route
        path={tenantUsersRoutes.MEMBER_PAGE}
        element={
          <UseParams<{ memberId: string }>>
            {({ memberId }) => (
              <Layout currentPage="directory">
                <AbilityRouteProtector
                  can={(ability) => ability.can(ActionsEnum.view, userProfileSubject({ id: memberId }))}
                >
                  <MemberProfileView />
                </AbilityRouteProtector>
              </Layout>
            )}
          </UseParams>
        }
      />

      <Route
        path={tenantUsersRoutes.DIRECTORY_PAGE}
        element={
          <Layout currentPage="directory">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view_directory, SubjectsEnum.Company)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Directory' })} />
              <DirectoryView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        element={<CustomReportRoutes />}
        path={`${routes.CUSTOM_REPORT_LIST}/*`}
      />

      <Route
        path={routes.DATA_CONNECTORS}
        element={
          <Layout currentPage="data">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connectors' })} />
              <ConnectorsListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.CAPTURED_RECORD_MODEL_DETAILS}
        element={
          <Layout currentPage="data">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Data)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data' })} />
              <CapturedRecordModelDetailsView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={`${routes.DATA_ROOT}/*`}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Data)}>
            <CustomObjectRoutes />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={`${routes.COMPANY_SETTINGS}/*`}
        element={
          <AbilityRouteProtector
            can={(ability) =>
              ability.can(ActionsEnum.view_rates, SubjectsEnum.Company) ||
              ability.can(ActionsEnum.view_directory, SubjectsEnum.Company) ||
              ability.can(ActionsEnum.view_settings, SubjectsEnum.Company)
            }
          >
            <CompanySettings />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.THREADS}
        element={
          <Layout>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Threads' })} />
            <ThreadsView />
          </Layout>
        }
      />

      <Route
        path={`${routes.SUPERADMIN}/*`}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
            <SuperAdminRoutes />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.DASHBOARD}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Dashboard' })} />
            <DashboardView />
          </Fragment>
        }
      />

      <Route
        element={<DashboardsV2ListRoutes />}
        path={`${routes.DASHBOARDS_V2}`}
      />

      <Route
        element={<DashboardsV2DetailsRoutes />}
        path={`${routes.DASHBOARD_V2}`}
      />

      <Route
        path={routes.STATEMENT_COMMENT}
        element={
          <Layout currentPage="statements">
            <AbilityRouteProtector
              can={(ability) => ability.can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement)}
            >
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetailsView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.STATEMENT_COMMENT_CREATE}
        element={
          <Layout currentPage="statements">
            <AbilityRouteProtector
              can={(ability) => ability.can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement)}
            >
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetailsView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.STATEMENT_RULE}
        element={
          <Layout currentPage="statements">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetailsView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.STATEMENT}
        element={
          <Layout currentPage="statements">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetailsView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.FORECAST}
        element={
          <Layout currentPage="forecasts">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecast' })} />
              <StatementDetailsView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.STATEMENTS_BY_USER_PERIOD}
        element={
          <Layout currentPage="statements">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <StatementNotFoundView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.FORECASTS}
        element={
          <Layout currentPage="forecasts">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecasts' })} />
              <StatementsListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.FORECASTS_BY_DATE}
        element={
          <Layout currentPage="forecasts">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecasts' })} />
              <StatementsListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.STATEMENTS}
        element={
          <Layout currentPage="statements">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <StatementsListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.STATEMENTS_BY_DATE}
        element={
          <Layout currentPage="statements">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <StatementsListView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        element={<HomeRedirectView />}
        path="*"
      />
    </RootRoutes>
  );
};

export default App;
