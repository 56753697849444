import { Fragment, memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, RadioButtonGroup, type RadioButtonGroupProps } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { type AssignmentStatusFilter } from '../../hooks/use-assignments-status-filter';

export type AssignmentsStatusFilterProps = Pick<
  RadioButtonGroupProps<AssignmentStatusFilter>,
  'onChange' | 'options' | 'value'
> & {
  readonly hasSelectedAssignments?: boolean;
  readonly onResetSelectedAssignments?: () => void;
};

export const AssignmentsStatusFilter = memo(function AssignmentsStatusFilter({
  hasSelectedAssignments,
  onResetSelectedAssignments,
  onChange,
  ...props
}: AssignmentsStatusFilterProps) {
  const [nextStatus, setNextStatus] = useState<AssignmentStatusFilter>(props.value);

  const { isConfirmModalOpen, setConfirmModalOpenTrue, setConfirmModalOpenFalse } = useBoolState(
    false,
    'confirmModalOpen',
  );

  const handleChange = useCallback(
    (newValue: AssignmentStatusFilter) => {
      if (hasSelectedAssignments) {
        setNextStatus(newValue);
        setConfirmModalOpenTrue();
      } else {
        onChange?.(newValue);
      }
    },
    [hasSelectedAssignments, onChange, setConfirmModalOpenTrue],
  );

  const handleConfirm = useCallback(() => {
    onResetSelectedAssignments?.();
    setConfirmModalOpenFalse();
    onChange?.(nextStatus);
  }, [onChange, onResetSelectedAssignments, setConfirmModalOpenFalse, nextStatus]);

  return (
    <Fragment>
      <RadioButtonGroup<AssignmentStatusFilter>
        {...props}
        name="assignment-status-filter"
        onChange={handleChange}
      />

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={setConfirmModalOpenFalse}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage defaultMessage="Switch view and reset user selection" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage defaultMessage="Switching view will reset your current user selection.{br}Do you want to continue?" />
          </Modal.Body>
        </Modal.Content>

        <Modal.Actions>
          <Modal.CancelAction />

          <Modal.MainAction onClick={handleConfirm}>
            <FormattedMessage defaultMessage="Confirm" />
          </Modal.MainAction>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
});
