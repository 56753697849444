import { round } from 'lodash';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';
import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';

import { formatAmount } from './formatAmount';

const MIN_AMOUNT = 0.01;

const numberFormatter = new Intl.NumberFormat();

/** 0.01 => 2 decimal places. */
const getDecimalPlacesFromRoundingConstant = (roundingConstant: number): number =>
  Math.max(Math.ceil(Math.log10(1 / roundingConstant)), 0);

export const roundNumber = (numberToRound?: number | null, step = 0.01): number => {
  // If payment is next to 0, return 0
  if (!numberToRound || (numberToRound < MIN_AMOUNT && numberToRound > -1 * MIN_AMOUNT)) {
    return 0;
  }

  return round(numberToRound, getDecimalPlacesFromRoundingConstant(step));
};

const formatTotalForDate = (total: number | string): string => {
  const isUnixTimestamp = dayjs(total, 'X', true).isValid();
  if (isUnixTimestamp) {
    return dayjs(total, 'X').format('YYYY-MM-DD');
  }

  return `${total}`;
};

const formatTotalForPercent = (total: number): string =>
  new Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumSignificantDigits: 1,
    roundingPriority: 'lessPrecision',
  }).format(roundNumber(total * 100, 0.01) / 100);

const formatTotalForNumber = (total: number): string => numberFormatter.format(roundNumber(total));

export const formatTotal = (
  total: ComputeEnginePrimitiveTypes | undefined,
  format: FormatsEnum,
  currencySymbol: CurrencySymbolsEnum = CurrencySymbolsEnum.EUR,
  currencyRate: number = 1,
): number | string => {
  if (total === 'Infinity') {
    return Infinity;
  }
  if (total === null) {
    return 'null';
  }
  switch (format) {
    case FormatsEnum.table:
      return '';
    case FormatsEnum.text:
      return `${total}`;
    case FormatsEnum.date:
    case FormatsEnum['date-time']:
      return formatTotalForDate(total as number | string);
    case FormatsEnum.percent:
      return formatTotalForPercent(total as number);
    case FormatsEnum.number:
      return formatTotalForNumber(total as number);
    case FormatsEnum.boolean:
      return `${total}`;
    // Default usage for this function is to format as currency.
    case FormatsEnum.currency:
    default:
      return formatAmount(roundNumber(total as number) * currencyRate, currencySymbol);
  }
};

export const formatValueTotal = (value: ComputeEnginePrimitiveTypes | undefined): string | undefined =>
  value === null || value === undefined ? undefined : value.toString();

export const formatValueOrPrintRemovedLabel = (value: number | string | null | undefined): string | undefined =>
  value === null || value === undefined || value === '' ? 'deleted' : formatValueTotal(value);

export const formatDatasetCell = (
  cellValue: ComputeEnginePrimitiveTypes,
  forceFormat?: FormatsEnum,
): number | string | undefined => {
  // Only parse as date if it's a timestamp, otherwise show the exact value
  if (typeof cellValue === 'number' && forceFormat === FormatsEnum.date && cellValue > 10_000) {
    return cellValue ? dayjs.utc(cellValue, 'X').format('YYYY-MM-DD') : '';
  }

  if (forceFormat) {
    const value =
      cellValue && typeof cellValue === 'object' && 'value' in cellValue ? cellValue.value : (cellValue as number);
    return formatTotal(value, forceFormat);
  }

  // Case for currencies.
  if (isCurrencyValue(cellValue)) {
    const { value, symbol } = cellValue;
    return formatTotal(value, FormatsEnum.currency, symbol);
  }

  // Display as-is.
  return formatValueTotal(cellValue);
};
