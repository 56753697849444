import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineDayjsInput } from '../dates.types';

export default new AmaliaFunctionDefault<[ComputeEngineDayjsInput], number>({
  name: AmaliaFunctionKeys.startOfYear,
  category: AmaliaFunctionCategory.DATES,
  nbParamsRequired: 1,
  description: 'Return the first day of year of a given date',

  exec: (date: ComputeEngineDayjsInput) => dayjs(date, 'X').startOf('year').unix(),

  params: [
    {
      name: 'date',
      description:
        'Date to apply function on: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
  ],

  examples: [
    {
      desc: 'Returns "2023-01-01"',
      formula: 'startOfYear(toDate("2023-05-19"))' as AmaliaFormula,
      result: 1_672_531_200,
    },
    {
      desc: "Returns first day of year of the user's plan assignment start date.",
      formula: 'startOfYear(planAssignement.effectiveAsOf)' as AmaliaFormula,
    },
    {
      desc: 'Returns first day of year of opportunity close date.',
      formula: 'startOfYear(opportunity.closeDate)' as AmaliaFormula,
    },
  ],
});
