/* eslint-disable react/jsx-handler-names */
import { useTheme } from '@emotion/react';
import { IconDots, IconFiles, IconHome, IconHomeOff, IconTrash } from '@tabler/icons-react';
import { Fragment, memo } from 'react';
import { useIntl } from 'react-intl';

import { IconButton, MenuDropdown } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';
import { useBoolStates } from '@amalia/ext/react/hooks';
import { DashboardV2DeleteModal, DashboardV2DuplicateModal } from '@amalia/reporting/dashboards-v2/components';
import { type Dashboard } from '@amalia/reporting/dashboards-v2/types';

import { DashboardSetHomeModal } from '../modals/DashboardSetHomeModal';
import { DashboardUnsetHomeModal } from '../modals/DashboardUnsetHomeModal';

type DashboardV2DetailsHeaderMenuProps = {
  readonly canModifyDashboard: boolean;
  readonly dashboard?: Dashboard;
  readonly isDashboardSetAsHome: boolean;
};

export const DashboardV2DetailsHeaderMenu = memo(function DashboardV2DetailsHeaderMenu({
  canModifyDashboard,
  dashboard,
  isDashboardSetAsHome,
}: DashboardV2DetailsHeaderMenuProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const dashboardModalVisibility = useBoolStates([
    'setHomeModalOpen',
    'unsetHomeModalOpen',
    'deleteDashboardModalOpen',
    'duplicateDashboardModalOpen',
  ] as const);

  return (
    <Fragment>
      <MenuDropdown
        content={
          <Fragment>
            {isDashboardSetAsHome ? (
              <MenuDropdown.Item
                icon={<IconHomeOff />}
                label={formatMessage({ defaultMessage: 'Remove from home' })}
                onClick={dashboardModalVisibility.setUnsetHomeModalOpenTrue}
              />
            ) : (
              <MenuDropdown.Item
                icon={<IconHome />}
                label={formatMessage({ defaultMessage: 'Set as home' })}
                onClick={dashboardModalVisibility.setSetHomeModalOpenTrue}
              />
            )}

            {!!canModifyDashboard && (
              <Fragment>
                <MenuDropdown.Item
                  icon={<IconFiles />}
                  label={formatMessage({ defaultMessage: 'Duplicate dashboard' })}
                  onClick={dashboardModalVisibility.setDuplicateDashboardModalOpenTrue}
                />

                <MenuDropdown.Item
                  icon={<IconTrash color={theme.ds.colors.danger[500]} />}
                  label={formatMessage({ defaultMessage: 'Delete dashboard' })}
                  onClick={dashboardModalVisibility.setDeleteDashboardModalOpenTrue}
                />
              </Fragment>
            )}
          </Fragment>
        }
      >
        <MenuDropdown.IconButton
          withBackground
          aria-label={formatMessage({ defaultMessage: 'Open actions' })}
          icon={<IconDots color={theme.ds.colors[ColorCategory.PRIMARY][500]} />}
          label={formatMessage({ defaultMessage: 'Open actions' })}
          size={IconButton.Size.MEDIUM}
        />
      </MenuDropdown>

      {!!dashboard && (
        <Fragment>
          <DashboardSetHomeModal
            dashboardId={dashboard.id}
            dashboardName={dashboard.name}
            isOpen={dashboardModalVisibility.isSetHomeModalOpenTrue}
            onClose={dashboardModalVisibility.setSetHomeModalOpenFalse}
          />

          <DashboardUnsetHomeModal
            dashboardName={dashboard.name}
            isOpen={dashboardModalVisibility.isUnsetHomeModalOpenTrue}
            onClose={dashboardModalVisibility.setUnsetHomeModalOpenFalse}
          />

          {!!canModifyDashboard && (
            <Fragment>
              <DashboardV2DeleteModal
                shouldRedirectToDashboardsAfterDelete
                dashboard={dashboard}
                isOpen={dashboardModalVisibility.isDeleteDashboardModalOpenTrue}
                onClose={dashboardModalVisibility.setDeleteDashboardModalOpenFalse}
              />

              <DashboardV2DuplicateModal
                shouldRedirectToDashboardAfterDuplicate
                dashboard={dashboard}
                isOpen={dashboardModalVisibility.isDuplicateDashboardModalOpenTrue}
                onClose={dashboardModalVisibility.setDuplicateDashboardModalOpenFalse}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});
